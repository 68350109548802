"use client"
import styled from "@emotion/styled"
import { ItemType } from "lib/utils"
import Image from "next/image"
import Link from "next/link"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import { NewText, ReadMoreIcon } from "ui"
import { getNonUmbracoURL, httpsImage } from "utils/utils"

const StyledItemContainer = styled(Link, {
  shouldForwardProp: (prop) =>
    prop !== "transformLeft" &&
    prop !== "transformRight" &&
    prop !== "mobileScale",
}) <{
  transformLeft?: boolean
  transformRight?: boolean
  mobileScale?: boolean
}>`
  height: 280px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  transition: 200ms all ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  box-sizing: border-box;
  padding: 28px 24px 28px 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) =>
    props.mobileScale
      ? `
      transform: scale(1.065);
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.3);


    `
      : null}
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 32px 43px 32px 43px;
    box-shadow: none;
    :hover {
      transform: scale(1.065);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 28px 24px 28px 24px;
  }
`

const StyledIcon = styled.div`
  height: 100px;
  width: 200px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 80px;
    width: 160px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 90px;
    width: 180px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const StyledCTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline-end: auto;
  position: absolute;
  bottom: 15px;
  left: 30px;
  color: ${(props) => props.theme.colors.blue};
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  ${(props) => props.theme.breakpoints.up("md")} {
    line-height: 18px;
    font-size: 13.7px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    line-height: 20px;
    font-size: 14px;
  }
`

const StyledTitleAndText = styled.div`
`
type Props = {
  item: ItemType
  transformLeft?: boolean
  transformRight?: boolean
  mobileScale?: boolean
}

export default function BrandsBox({
  item,
  transformLeft,
  transformRight,
  mobileScale,
}: Props) {
  return (
    <StyledItemContainer
      href={getNonUmbracoURL(item.url)}
      transformLeft={transformLeft}
      transformRight={transformRight}
      mobileScale={mobileScale}
    >
      <StyledTitleAndText>
        <StyledIcon>
          <Image
            priority
            style={{ objectFit: "contain" }}
            src={httpsImage(item.icon)}
            fill
            alt={item.text.replace('עוד על הלוואה ', '').replace('עוד על ', '')}
          />
        </StyledIcon>
        <NewText
          component={"p"}
          largeDesktopFontSize={16}
          largeDesktopLineHeight={"24px"}
          desktopFontSize={12}
          desktopLineHeight={"18px"}
          mobileFontSize={16}
          mobileLineHeight={"24px"}
          color="black"
          style={{ opacity: 0.6 }}
          dangerouslySetInnerHTML={{ __html: item.description || "" }}
        />
      </StyledTitleAndText>
      <StyledCTA>
        {item.text}
        <ReadMoreIcon />
      </StyledCTA>
    </StyledItemContainer>
  )
}
