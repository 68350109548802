"use client"
import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import { ItemType, TabType, convertToTabsData } from "lib/utils"
import { useState } from "react"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import { Navigation, Pagination, Scrollbar } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Desktop, Mobile, NewText, NextArrow } from "ui"
import { sharedColors } from "ui/theme/colors"
import BrandsBox from "./BrandsBox"

const StyledSwiper = styled(Swiper)`
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 10px 20px 40px 0;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 20px 20px 50px 0;
  }
`

const StyledMobileSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column-reverse;
  .swiper-scrollbar {
    position: static;
    margin-bottom: 42px;
    background-color: white;
    height: 1px;
  }
  .swiper-scrollbar-drag {
    height: 4px;
    background-color: white;
    width: 50px;
  }
`

const StyledBackground = styled.div<{ $background: string }>`
  background: ${(props) => props.$background};
  padding: 32px 20px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 40px 146px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 60px 220px;
  }
`

const StyledTabs = styled(Tabs)`
  border-top: 1px solid #e5edfa;
  svg {
    color: white;
  }
  .MuiTab-root {
    border-top: 4px solid transparent;
    padding-top: 0;
    color: ${(props) => props.theme.colors.white};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  .Mui-selected {
    border-top: 4px solid white;
    color: ${(props) => props.theme.colors.white} !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 0px;
  }
`

const StyledTitleContainer = styled.div`
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 20px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledTab = styled(Tab)`
  padding-right: 20vw;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-right: 10vw;
  }
  &.MuiTab-root {
    ${(props) => props.theme.breakpoints.up("md")} {
      line-height: 60px;
    }
  }
  & :focus-visible {
    border: 1px gray solid;
  }
`

const StyledRelative = styled.div`
  position: relative;
`
const StyledNext = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -50px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: -50px;
  }
`

const StyledPrev = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  left: -50px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    left: -50px;
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 244px !important;
  margin-right: 1px !important;
  box-sizing: border-box;
`
const SLIDES_DESKTOP = 6

const CorpBrands: React.FC<{
  data: any
  background?: string
  mainTitle?: string
  height?: string
  width?: string
  margin?: string
  keyForComponent?: any
  enabledScrollbar?: boolean
  paddingTop?: number
  mWidth?: string
  mHeight?: string
  mainTitleColor?: string
}> = ({
  data,
  background = sharedColors.blue,
  mainTitle,
  keyForComponent,
  mainTitleColor,
}) => {
    const tabsData = convertToTabsData(data)
    const [value, setValue] = useState(0)
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    }
    const [active, setActive] = useState<number | null>(0)
    return (
      <StyledBackground $background={background}>
        <>
          <Desktop>
            <StyledTitleContainer>
              <NewText
                desktopFontSize={32}
                component="h2"
                desktopLineHeight="150%"
                desktopFontWeight="500"
                color={mainTitleColor || sharedColors.white}
              >
                {mainTitle}
              </NewText>
            </StyledTitleContainer>
            {tabsData.length > 1 ? (
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="מותגי הקבוצה"
              >
                {tabsData.map((tab: TabType, index: number) => (
                  <StyledTab disableRipple label={tab.tabLabel} key={index} />
                ))}
              </StyledTabs>
            ) : null}
          </Desktop>
          <Mobile>
            <StyledTitleContainer>
              <NewText
                desktopFontSize={32}
                component="h2"
                desktopLineHeight="150%"
                desktopFontWeight="500"
                color={mainTitleColor || sharedColors.white}
              >
                {mainTitle}
              </NewText>
            </StyledTitleContainer>
            {tabsData.length > 1 ? (
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="מותגי הקבוצה"
              >
                {tabsData.map((tab: TabType, index: number) => (
                  <StyledTab
                    label={
                      <NewText
                        desktopFontSize={18}
                        component="h1"
                        desktopLineHeight="60px"
                        desktopFontWeight="500"
                        color={sharedColors.white}
                      >
                        {tab.tabLabel}
                      </NewText>
                    }
                    key={index}
                  />
                ))}
              </StyledTabs>
            ) : null}
          </Mobile>
        </>

        <Desktop>
          <StyledRelative>
            <StyledNext className={`arrow-left-swiper-${keyForComponent} arrow`} aria-label="הבא">
              <NextArrow />
            </StyledNext>
            <StyledPrev className={`arrow-right-swiper-${keyForComponent} arrow`} aria-label="הקודם">
              <NextArrow />
            </StyledPrev>

            <StyledSwiper
              navigation={{
                nextEl: `.arrow-left-swiper-${keyForComponent}`,
                prevEl: `.arrow-right-swiper-${keyForComponent}`,
              }}
              key={value}
              spaceBetween={0}
              modules={[Navigation, Scrollbar, Pagination]}
              slidesPerView={"auto"}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            >
              {tabsData[value].items.map((item: ItemType, index: number) => {
                return (
                  <StyledSwiperSlide
                    key={index.toString()}
                    style={{ zIndex: active === index ? 9 : 1 }}
                  >
                    <BrandsBox
                      item={item}
                      transformLeft={activeIndex === index}
                      transformRight={index === activeIndex + SLIDES_DESKTOP - 1}
                    />
                  </StyledSwiperSlide>
                )
              })}
            </StyledSwiper>
          </StyledRelative>
        </Desktop>
        <Mobile>
          <StyledMobileSwiper
            style={{ padding: 50, paddingRight: 20 }}
            key={2}
            grabCursor={true}
            slidesPerView={"auto"}
            initialSlide={0}
            onSlideChange={(swiper) => setActive(swiper.realIndex)}
            pagination={true}
            className="mySwiper"
          >
            {tabsData[value].items.map((item: ItemType, index: number) => {
              return (
                <StyledSwiperSlide
                  key={index.toString()}
                  style={{ zIndex: active === index ? 9 : 1 }}
                >
                  <BrandsBox item={item} mobileScale={active === index} />
                </StyledSwiperSlide>
              )
            })}
          </StyledMobileSwiper>
        </Mobile>
      </StyledBackground>
    )
  }

export default CorpBrands
